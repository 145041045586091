<template>
    <v-container fluid class="mx-auto">
        <v-row class="mt-10">
            <v-col>
                <v-card rounded="xl">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="items">
                            <template v-slot:item.actions="item">
                                <v-icon class="clickable" color="success" size="30" @click="update(item)">mdi-check-bold
                                </v-icon>&nbsp;
                                <v-icon class="clickable" color="red" size="30">mdi-close-thick</v-icon>&nbsp;
                                <v-dialog width="500" v-model="dialogInfo">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-on="on" v-bind="attrs" class="clickable" color="info" size="30">
                                            mdi-information</v-icon>&nbsp;
                                    </template>
                                    <v-card>
                                        <v-card-text>
                                            Info regarding transaction
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>

export default {
    data: () => ({
        dialog: false,
        dialogInfo: false,
        headers: [
            { text: "Date de la demande", value: "requested_at", align: "center" },
            { text: "Demander par", value: "requested_by", align: "center" },
            { text: "Transaction ID", value: "transaction_id", align: "center" },
            { text: "Merchant", value: "merchant_id", align: "center" },
            { text: "Transaction Status", value: "transaction_status", align: "center" },
            { text: "Actions", value: "actions", align: "center" }
        ],
        items: [{
            requested_at: "24-01-2022",
            requested_by: "Nathan Sadala",
            transaction_id: "123323123",
            merchant_id: "1xbet",
            transaction_status: "Pending"
        }]
    }),
    methods: {
        update(item) {
            // c'est ici qu'on va update l'item dans la base de données puis on va recharger la vue
            alert(item)
        }
    }
}
</script>


<style scoped>
.clickable {
    cursor: pointer;
}
</style>